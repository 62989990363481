<template>
	<div class="detail-item flex">
		<div class="right w-192">
			<div class="top">文件或文件夹</div>
			<el-divider></el-divider>
			<div class="bottom">
				<div class="fileImg-box relative mr-20" @mouseenter="showText = true" @mouseleave="showText = false" v-if="item.fileUrl">
					<div v-show="showText" class="flex items-center justify-center img-model absolute w-full h-full opticy-7">
						<el-button type="primary" @click="toReadOrDownload()">查看并下载</el-button>
					</div>
					<img class="fujian mt-24" mode="widthFix" :src="item.filePic||(VUE_APP_FILEURL + 'public-mticloud-app-mds/2024/05/08/aa1a8d95-d986-467c-9ea4-02ec6938787b.png')" />
				</div>
				<empty :name="'暂无数据'" v-else></empty>
			</div>
		</div>
		<div class="left pl-24 w-full">
			<div class="row-1 flex items-center">
				<img class="header round-full" :src="item.imgUrl || VUE_APP_FILEURL + 'public-mticloud-app-mds/2024/05/07/b7d03199-d925-45da-98d0-ffadcebc2f69.png'" />
				<div class="nickname ml-24 flex-1">
					<div>{{ item.userName }}</div>
					<!-- <div class="time mt-14" v-if="item && item.createDate">{{ $utils.timeAgo(item.createDate) }}</div> -->
					<div class="time mt-14" v-if="item && item.createDate">{{ item.createDate }}</div>
				</div>
				<follow v-model="item.isFollow" :followConsumerId="item.consumerId"></follow>
			</div>
			<div class="row-2 mt-24 flex items-center">
				<div class="type mr-12" v-for="(ite, i) in typeList" :key="i" v-show="type == '全部' && item.shareType == ite.dictValue">
					{{ ite.dictLabel }}
				</div>
				<div class="title" v-html="item.title"></div>
			</div>
			<div class="row-3 mt-14 word-break-all" v-html="item.publishContent"></div>
			<div class="row-4 mt-24 flex h-24">
				<div class="keyword line-clamp-1" v-show="item.keyword" v-html="item.keyword"></div>
			</div>
			<div class="publish-content-box"> </div>
			<!-- <img @click="toDownload()" class="fujian mt-24" mode="widthFix" :src="VUE_APP_FILEURL + 'public-mticloud-app-mds/2024/05/08/aa1a8d95-d986-467c-9ea4-02ec6938787b.png'" /> -->
			<div class="row-5 mt-20 flex items-center " v-if="type != '我的发布'">
				<div class="flex-1"></div>
				<div class="info">阅读数: {{ item.realReading }}</div>
				<div class="info ml-32">下载量: {{ item.realDownload }}</div>
			</div>
		</div>
		<el-dialog :visible.sync="fileVisible" width="80%" :close-on-click-modal="false" center append-to-body :top="'0'">
			<div class="file-box">
				<h4 class="text-center mb-10">{{ item.fileName }}</h4>
				<div class="preview-box w-full overflow-auto mb-10" style="height: 600px;">
					<div v-if="item.fileExtension === 'zip' || item.fileExtension === 'rar' || item.fileExtension === '7z' ">
						<img :src="item.filePic" />
					</div>
					<iframe :src="kkFileUrl + previewUrl"  class="preview-iframe w-full" style="height: 100%;" v-else></iframe>
				</div>
				<div class="text-center">
					<el-button type="primary" @click="goDownload">立即下载</el-button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import collect from "@/components/collect/index";
import follow from "@/components/follow";
import Empty from "@/components/Empty";
import {mapGetters} from "vuex";
import {Base64} from "js-base64";

export default {
	components: {
		collect,
		follow,
		Empty,
	},
	props: {
		shareId: {
			type: String,
			default: "",
		},
		typeList: {
			type: Array,
			default: () => {
				return [];
			},
		},
		item: {
			type: Object,
			default: () => {
				return {};
			},
		},
	},
	data() {
		return {
			id: "",
			showType: false,
			type: "全部",
			showState: false,
			localFileUrl: "",
			searcherTimer: "",
			shareModel: false,
			VUE_APP_FILEURL: process.env.VUE_APP_FILEURL,
			// 文件弹窗
			fileVisible: false,
			showText: false,
			previewUrl: "",
		};
	},
	created() {},
	mounted() {
		this.previewUrl = "/onlinePreview?url=" + encodeURIComponent(Base64.encode(this.item.fileUrl));
		console.log(111,this.kkFileUrl + this.previewUrl);
	},
	watch:{
		'item.fileUrl': {
			handler(newVal, oldVal) {
				console.log(newVal,oldVal);
				// 在这里执行相应的操作
				this.previewUrl = "/onlinePreview?url=" + encodeURIComponent(Base64.encode(newVal));
			},
			deep: true,
			//立即处理
			immediate: false,
		},
	},
	computed: {
		...mapGetters(["kkFileUrl"]),
	},
	methods: {
		// 点击下载
		toReadOrDownload() {
			this.fileVisible = true;
		},
		goDownload() {
			let item = this.item;
			if (item.shareSource == 611101) {
				let ruleTable = item.mdsMemberSharePermissionVOS; //权限列表
				let memberId = this.$session.getUsers().memberId; //用户身份
				let canRed = false; //下载权限
				let buyvip = "";
				let isUpgradation = "";
				ruleTable.map(item => {
					if (item.memberId == memberId && item.isDownload == 10011001) {
						canRed = true;
					}
				});
				if (canRed || memberId == 6) {
					this.toDownload();
				} else {
					let str = "";
					if (memberId == 1 || memberId == 3 || memberId == 5 || memberId == 7) {
						//注册用户
						let huiyuanRed = false;
						let vipRed = false;
						let tbRed = false;

						ruleTable.map(item => {
							if (item.memberId == 3 && item.isDownload == 10011001) {
								huiyuanRed = true;
							}
							if (item.memberId == 1 && item.isDownload == 10011001) {
								vipRed = true;
							}
							if (item.memberId == 5 && item.isDownload == 10011001) {
								tbRed = true;
							}
						});
						if (huiyuanRed) {
							str = "尊敬的用户，该内容仅限会员下载";
						}
						if (!huiyuanRed && vipRed) {
							str = "尊敬的用户，该内容仅限VIP会员下载";
							buyvip = "update";
							isUpgradation = "upgradation";
						}
						if (!huiyuanRed && !vipRed && tbRed) {
							str = "尊敬的用户，该内容仅限特别会员下载";
							this.$session.warning(str);
							return;
						}
					} else if (memberId == 3) {
						//普通会员
						str = "尊敬的用户，该内容仅限VIP会员下载";
						isUpgradation = "upgradation";
					}
					this.$confirm(str, "提示", {
						confirmButtonText: "去购买",
						cancelButtonText: "我知道了",
						type: "warning",
					})
						.then(() => {
							this.toMember(isUpgradation);
						})
						.catch(() => {
							console.log("用户点击取消");
						});
				}
				return;
			}
			this.toDownload();
		},
		toMember(isUpgradation) {
			let url = this.$router.resolve({
				path: `/member`,
				query: {
					isUpgradation: isUpgradation,
				},
			});
			window.open(url.href, "_blank");
		},
		toDownload() {
			const loading = this.$loading({
				lock: true,
				text: "下载中...",
				spinner: "el-icon-loading",
				background: "rgba(255, 255, 255, 0.3)",
				fullscreen: false,
			});
			this.fileDownload(this.item.fileUrl, this.item.fileName);
			loading.close();
		},
		// 调用downLoad方法下载文件，以下是具体方法：
		//************************修改文件下载的名称*********start************************
		/**
		 * 获取 blob
		 * @param  {String} url 目标文件地址
		 * @return {Promise}
		 */
		getBlob(url) {
			return new Promise(resolve => {
				const xhr = new XMLHttpRequest();
				// 避免 200 from disk cache
				url = url + `?r=${Math.random()}`;
				xhr.open("GET", url, true);
				xhr.responseType = "blob";
				xhr.onload = () => {
					if (xhr.status === 200) {
						resolve(xhr.response);
					}
				};
				xhr.send();
			});
		},

		/**
		 * 保存
		 * @param  {Blob} blob
		 * @param  {String} filename 想要保存的文件名称
		 */
		saveAs(blob, filename) {
			if (window.navigator.msSaveOrOpenBlob) {
				navigator.msSaveBlob(blob, filename);
			} else {
				const anchor = document.createElement("a");
				const body = document.querySelector("body");
				anchor.href = window.URL.createObjectURL(blob);
				anchor.download = filename;
				console.log(filename, "filename");
				anchor.style.display = "none";
				body.appendChild(anchor);
				anchor.click();
				body.removeChild(anchor);
				window.URL.revokeObjectURL(anchor.href);
			}
			//存储下载记录
			let dataParams = {
				// localFileUrl: savedFilePath,
				relationId: this.item.shareId,
				consumerId: this.$session.getUsers().consumerId,
				relationType: "60611011",
			};
			console.log("联网打开成功,下载记录 ", dataParams);
			//打开文件同时存储下载记录
			this.$api.viparea.shareDownloadFile(dataParams);
		},

		/**
		 * 下载
		 * @param  {String} url 目标文件地址
		 * @param  {String} newFilename 想要保存的文件名称
		 */
		async fileDownload(url, newFilename) {
			const blob = await this.getBlob(url);
			console.log(blob, "blob");
			this.saveAs(blob, newFilename);
		},

		//************************修改文件下载的名称*********end************************
	},
};
</script>

<style lang="less">
/* 不加密 */
.mgc-text {
	display: none;
}
.detail-item {
	box-sizing: border-box;
	height: 100%;
	padding: 26px 32px;
	.right {
		border-right: 1px solid #d7d7d7;
		.bottom {
			.fileImg-box {
				.img-model {
					background-color: #000000;
				}
			}
		}
	}
	.left {
		display: flex;
		flex-direction: column;
		.row-1 {
			.header {
				width: 45px;
				height: 45px;
			}

			.nickname {
				font-size: 17px;
				color: rgba(51, 51, 51, 1);
			}

			.vip {
				width: 152px;
				height: 40px;
			}

			.time {
				font-size: 12px;
				color: rgba(153, 153, 153, 1);
			}

			.state {
				width: 100px;
				opacity: 0.8;
				margin-left: 20px;
			}

			.follow {
				width: max-content !important;
				font-size: 14px;
				color: #999;
				display: flex;
				align-items: center;
				background-color: aliceblue;
				padding: 15px 25px;
				border-radius: 32px;
				white-space: nowrap;
			}
		}

		.row-2 {
			.type {
				white-space: nowrap;
				width: max-content;
				flex-shrink: 0;
				padding: 5px 12px;
				opacity: 0.9;
				border-radius: 4px;
				background: #3e7ef2;
				color: #fff;
				font-size: 12px;
			}

			.title {
				font-size: 17px;
				font-weight: bold;
				color: rgba(51, 51, 51, 1);
			}
		}

		.row-3 {
			font-size: 14px;
			color: rgba(102, 102, 102, 1);
		}

		.row-4 {
			.keyword {
				border-radius: 21px;
				background: #f0f4f9;
				padding: 5px 18px;
				font-size: 12px;
				color: #3e7ef2;
				line-height: 17px;
				margin-right: 20px;
			}
		}
		.publish-content-box {
			flex: 1;
		}

		.fujian {
			height: 100px;
			width: 100px;
		}

		.row-5 {
			.info {
				font-size: 12px;
				color: rgba(153, 153, 153, 1);
			}
		}
		.optr {
			position: fixed;
			width: 100vw;
			left: 0;
			bottom: 0px;
			box-sizing: border-box;
			box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.2);
			background-color: #ffffff;
			font-size: 12px;
			color: rgba(153, 153, 153, 1);
			align-items: center;
			.type2 {
				white-space: nowrap;
				width: max-content;
				flex-shrink: 0;
				padding: 10px 25px;
				opacity: 0.9;
				border-radius: 4px;
				background: #3e7ef2;
				color: #fff;
				font-size: 14px;
			}
			.share {
				position: absolute;
				right: 180px;
				width: 100px;
				height: 60px;
				bottom: 25px;
				border: none;
			}

			.ml-0 {
				margin-left: 0 !important;
			}
		}
	}
}
</style>
