<template>
	<div class="viparea-box">
		<div class="viparea-box-left p-14">
			<div class="title-box flex items-center">
				<div class="title-right mr-14"></div>
				<div class="title">会员专区</div>
			</div>
			<el-divider></el-divider>
			<div class="option-box h-192">
				<div class="option-item px-14 h-40 lh-40 mb-10 round-20" :class="activeIndex == index ? 'option-item-active' : ''" v-for="(item, index) in optionList" :key="index" @click="handleOption(index)">
					<i :class="item.iconName"></i>
					<span class="ml-14">{{ item.name }}</span>
				</div>
			</div>
			<div class="button-box">
				<el-button class="w-full button" type="primary" round @click="handleAdd">+&nbsp;&nbsp;发布</el-button>
			</div>
		</div>
		<div class="viparea-box-right p-20">
			<div class="recommendation-box" v-if="activeIndex === 0">
				<div class="top w-384">
					<el-input placeholder="请输入内容" @keyup.enter.native="recommendationSearch" v-model="conPO.title">
						<i slot="suffix" class="el-input__icon el-icon-search cursor-pointer" @click="recommendationSearch"></i>
						<!-- <el-button slot="append" icon="el-icon-search" @click="recommendationSearch"></el-button> -->
					</el-input>
				</div>
				<div class="bottom mt-20">
					<el-tabs v-model="activeName" @tab-click="handleClick">
						<el-tab-pane :label="element.name" :name="element.name" v-for="element in typeList" :key="element.name">
							<div class="toppingBox flex flex-wrap justify-start">
								<div class="w-384  round-8 item-box mx-14 mt-20" v-for="item in topDataList" :key="item.shareId">
									<ListItem :item="item" :type="element.name" :typeList="typeListDict" @toDetail="toDetail"></ListItem>
								</div>
							</div>
							<div class="w-384 m-auto pr-10" v-show="topDataList.length > 0">
								<el-divider class="w-384" content-position="center">以上是置顶内容</el-divider>
							</div>
							<div class="listBox flex flex-wrap justify-start">
								<div class="w-384  round-8 item-box mx-14 mt-20" v-for="item in dataList" :key="item.shareId">
									<ListItem :item="item" :type="element.name" :typeList="typeListDict" @toDetail="toDetail"></ListItem>
								</div>
								<div class="pagination-box mt-20 w-full">
									<pagination ref="pagination" :modelName="'会员专区'" :pageSizeNew="9" :pageSizeOptions="[9, 18, 27, 36, 45]" :total="total" @change="paginChange" v-show="total != 0" :key="paginationKey"></pagination>
								</div>
							</div>
							<div class="empty-box" v-show="total == 0">
								<empty :name="'暂无数据'"></empty>
							</div>
						</el-tab-pane>
					</el-tabs>
				</div>
			</div>
			<div class="my-box" v-if="activeIndex === 1">
				<div class="top">
					<el-tabs v-model="myActiveName" @tab-click="handleMyTabClick">
						<div class="top w-384">
							<el-input placeholder="请输入内容" @keyup.enter.native="mySearch" v-model="conPO.title">
								<i slot="suffix" class="el-input__icon el-icon-search cursor-pointer" @click="mySearch"></i>
							</el-input>
						</div>
						<el-divider class="w-384" content-position="center"></el-divider>
						<el-tab-pane :label="element.name" :name="element.name" v-for="element in myTypeList" :key="element.name">
							<div class="my-release flex flex-wrap justify-start" v-show="element.name === '我的发布'">
								<div class="w-384 round-8 item-box mx-14 mt-20" v-for="item in myReleaseDataList" :key="item.shareId">
									<ListItem :item="item" :type="element.name" :typeList="typeListDict" @toDetail="toDetail"></ListItem>
								</div>
								<div class="empty-box m-auto" v-show="total == 0">
									<empty :name="'暂无数据'"></empty>
								</div>
								<div class="pagination-box mt-20 w-full">
									<pagination ref="pagination" :modelName="'会员专区'" :pageSizeNew="9" :pageSizeOptions="[9, 18, 27, 36, 45]" :total="total" @change="myReleasePaginChange" v-show="total != 0"></pagination>
								</div>
							</div>
							<div class="my-follow flex flex-wrap justify-between" v-show="element.name === '我的关注'">
								<div class="w-percentage45 round-8 item-box mx-14 mt-20" v-for="item in myFollowDataList" :key="item.shareId">
									<ListItemFollow :item="item"></ListItemFollow>
								</div>
								<div class="empty-box m-auto" v-show="total == 0">
									<empty :name="'暂无数据'"></empty>
								</div>
								<div class="pagination-box mt-20 w-full">
									<pagination ref="pagination" :modelName="'会员专区'" :total="total" @change="myFollowPaginChange" v-show="total != 0" :key="paginationKey"></pagination>
								</div>
							</div>
							<div class="my-collect flex flex-wrap justify-start" v-show="element.name === '我的收藏'">
								<div class="w-384 round-8 item-box mx-14 mt-20" v-for="item in myCollectDataList" :key="item.shareId">
									<ListItem :item="item" :type="element.name" :typeList="typeListDict" @toDetail="toDetail"></ListItem>
								</div>
								<div class="empty-box m-auto" v-show="total == 0">
									<empty :name="'暂无数据'"></empty>
								</div>
								<div class="pagination-box mt-20 w-full">
									<pagination ref="pagination" :modelName="'会员专区'" :pageSizeNew="9" :pageSizeOptions="[9, 18, 27, 36, 45]" :total="total" @change="myCollectPaginChange" v-show="total != 0" :key="paginationKey"></pagination>
								</div>
							</div>
							<div class="my-download" v-show="element.name === '我的下载'">
								<el-table :data="downloadTableData" border style="width: 100%" :header-cell-style="{background: '#01a7f0', color: '#ffffff'}">
									<el-table-column type="index" label="序号" width="100" align="center"></el-table-column>
									<el-table-column prop="fileName" label="文件名称" width="200" align="center"></el-table-column>
									<el-table-column prop="title" label="标题名称" width="200" align="center"></el-table-column>
									<el-table-column prop="publishDate" label="发布时间" width="200" align="center"></el-table-column>
									<el-table-column prop="downloadDate" label="下载时间" width="200" align="center"></el-table-column>
									<el-table-column prop="userName" label="发布人" width="200" align="center"></el-table-column>
									<el-table-column label="操作" width="149" align="center">
										<template slot-scope="scope">
											<el-button @click="toDownload(scope.row)" type="text" size="small">下载</el-button>
										</template>
									</el-table-column>
								</el-table>
								<pagination ref="pagination" :modelName="'会员专区'" :total="total" @change="myDownloadPaginChange" v-show="total != 0" :key="paginationKey"></pagination>
							</div>
						</el-tab-pane>
					</el-tabs>
				</div>
				<div class="bottom"></div>
			</div>
		</div>
		<el-dialog :visible.sync="addVisible" width="62%" class="addSubscribeDialog" :close-on-click-modal="false" center :top="'5vh'">
			<Add ref="add" :typeList="typeListDict" v-validate="'required'" :form.sync="form" v-if="addVisible"></Add>
			<span slot="footer" class="dialog-footer">
				<el-button @click="cancel" round>取 消</el-button>
				<el-button type="primary" @click="createNews" round>提 交</el-button>
			</span>
		</el-dialog>
		<el-dialog :visible.sync="detailVisible" fullscreen class="addSubscribeDialog" :close-on-click-modal="false" center>
			<Detail :shareId.sync="shareId" ref="detail" :typeList="typeListDict" :item="detailData"></Detail>
		</el-dialog>
		<el-dialog :visible.sync="reviewReminderVisible" title="审核提醒" width="50%" class="reviewReminderSubscribeDialog" :close-on-click-modal="false" :top="'25vh'">
			<div class="text-center text-18">您发布的内容已提交至后台，待管理员审核通过后即可正式发布</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="reviewReminderVisible = false" type="primary">我知道了</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
import ListItem from "./components/listItem.vue";
import ListItemFollow from "./components/listItem-follow.vue";
import Add from "./components/add.vue";
import Detail from "./components/detail.vue";
import Pagination from "@/components/Pagination";
import Empty from "@/components/Empty";
export default {
	name: "viparea",
	components: {
		ListItem,
		Pagination,
		Empty,
		Add,
		Detail,
		ListItemFollow,
	},
	mixins: [],
	props: {},
	data() {
		return {
			optionList: [
				{
					name: "推荐",
					iconName: "el-icon-receiving",
				},
				{
					name: "我的",
					iconName: "el-icon-user",
				},
			],
			activeIndex: 0,
			activeName: "全部",
			conPO: {
				pageSize: 9,
				pageNum: 1,
				title: "",
				shareType: "",
			},
			total: 0,
			typeList: [
				{
					name: "全部",
					value: "",
					tab: 0,
				},
				{
					name: "消息发布",
					value: "611001",
					tab: 0,
				},
				{
					name: "数据共享",
					value: "611002",
					tab: 0,
				},
				{
					name: "知识分享",
					value: "611003",
					tab: 0,
				},
			],
			myTypeList: [
				{
					name: "我的发布",
					value: "",
					tab: 1,
				},
				{
					name: "我的关注",
					value: "",
					tab: 1,
				},
				{
					name: "我的收藏",
					value: "",
					tab: 1,
				},
				{
					name: "我的下载",
					value: "",
					tab: 1,
				},
			],
			myActiveName: "我的发布",
			dataList: [],
			// 置顶数据
			topDataList: [],
			typeListDict: [], //内容类别
			paginationKey: "",
			// 新增
			addVisible: false, //新增弹窗
			form: {
				shareType: null,
				title: null,
				keyword: null,
				publishContent: null,
				fileId: null,
				shareSource: 611102,
				consumerId: null,
			},
			// 详情
			detailVisible: false,
			shareId: null,
			detailData: {},
			// 我的发布数据列表
			myReleaseDataList: [],
			//我的收藏数据列表
			myCollectDataList: [],
			//我的关注数据列表
			myFollowDataList: [],
			//我的下载数据列表
			downloadTableData: [],
			memberId: this.$session.getUsers().memberId,
			// 审核提醒
			reviewReminderVisible: false,
		};
	},
	computed: {},
	watch: {},
	async created() {
		this.getList();
		this.typeListDict = await this.$getDict(6110);
		this.form.consumerId = this.$session.getUsers().consumerId;
		this.paginChange();
	},
	beforeMount() {},
	mounted() {},
	methods: {
		// 重置搜索条件
		reset() {
			this.conPO = {
				pageSize: 9,
				pageNum: 1,
				title: "",
				shareType: "",
			};
		},
		//左侧 推荐/我的点击事件
		handleOption(index) {
			this.reset();
			this.activeIndex = index;
			console.log("this.activeIndex", this.activeIndex);
			console.log("this.myActiveName", this.myActiveName);
			if (this.activeIndex == 0) {
				this.handleClick();
			} else {
				this.handleMyTabClick();
			}
		},
		//推荐 tab点击事件
		handleClick() {
			this.reset();
			console.log(this.activeName);
			let activeValue = this.typeList.filter(item => {
				return item.name == this.activeName;
			})[0].value;
			this.conPO.shareType = activeValue;
			this.getList();
		},
		//我的 tab点击事件
		handleMyTabClick() {
			console.log("this.myActiveName", this.myActiveName);
			this.reset();
			if (this.myActiveName == "我的发布") {
				this.getList(this.myActiveName);
			} else if (this.myActiveName == "我的收藏") {
				this.getCollectionList();
			} else if (this.myActiveName == "我的关注") {
				this.getfollowPage();
			} else if (this.myActiveName == "我的下载") {
				this.getDownloadList();
			}
		},
		// 获取我的下载列表
		getDownloadList() {
			this.conPO.pageSize = 10;
			this.$api.viparea.getDownloadList({relationTypeList: "60611011", ...this.conPO}).then(res => {
				this.downloadTableData = res.rows;
				this.downloadTableData.map(element => {
					element.downloadConditionObj = JSON.parse(element.downloadCondition);
					element.title = element.downloadConditionObj.title;
					element.userName = element.downloadConditionObj.userName;
					element.publishDate = element.downloadConditionObj.createDate;
				});
				this.total = res.total;
				this.conPO.pageSize = 9;
			});
		},
		// 获取我的关注列表
		getfollowPage() {
			this.conPO.pageSize = 10;
			this.conPO.consumerId = this.$session.getUsers().consumerId;
			this.$api.viparea.followPage(this.conPO).then(res => {
				if (res.code == 200) {
					res.rows.map(item => {
						if (!item.isCollection && item.isCollection != 0) {
							item.isCollection = 0;
						}
					});
					this.myFollowDataList = res.rows;
					this.total = res.total;
				} else {
					this.$message.error(res.msg);
				}
				this.conPO.pageSize = 9;
			});
		},
		// 获取收藏列表
		getCollectionList() {
			// this.reset();
			this.$api.viparea.getCollectionList(this.conPO).then(res => {
				if (res.code == 200) {
					res.rows.map(item => {
						item.isCollection = 1;
					});
					this.myCollectDataList = res.rows;
					this.total = res.total;
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		// 获取消息列表
		getList(myActiveName) {
			if (myActiveName == "我的发布") {
				this.conPO.consumerId = this.$session.getUsers().consumerId;
				this.conPO.isMe = true; //是否是本人发布的
			} else {
				this.conPO.publishState = "101403";
				this.conPO.isMe = false; //是否是本人发布的
			}
			this.$api.viparea.page({...this.conPO, isTop: 1}).then(res => {
				if (res.code == 200) {
					this.topDataList = res.rows.filter(item => item.isTop == 1);
				} else {
					this.$message.error(res.msg);
				}
			});
			this.$api.viparea.page({...this.conPO, isTop: 0}).then(res => {
				if (res.code == 200) {
					res.rows.map(item => {
						if (!item.isCollection && item.isCollection != 0) {
							item.isCollection = 0;
						}
					});
					if (myActiveName == "我的发布") {
						this.myReleaseDataList = res.rows;
					} else {
						this.dataList = res.rows;
					}
					this.total = res.total;
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		getMyList() {},
		// 分页方法
		paginChange(page, pageSize) {
			// 判断用户是否登录
			if (this.$session.getUsers()) {
				this.conPO.pageNum = page || 1;
				this.conPO.pageSize = pageSize || 9;
				this.getList();
			} else {
				this.paginationKey = new Date().getTime();
			}
		},
		//我的发布分页方法
		myReleasePaginChange(page, pageSize) {
			this.conPO.pageNum = page;
			this.conPO.pageSize = pageSize;
			this.getList(this.myActiveName);
		},
		// 我的收藏分页方法
		myCollectPaginChange(page, pageSize) {
			this.conPO.pageNum = page;
			this.conPO.pageSize = pageSize;
			this.getCollectionList(this.myActiveName);
		},
		//我的关注分页方法
		myFollowPaginChange(page, pageSize) {
			this.conPO.pageNum = page;
			this.conPO.pageSize = pageSize;
			this.getfollowPage();
		},
		//我的下载分页方法
		myDownloadPaginChange(page, pageSize) {
			this.conPO.pageNum = page;
			this.conPO.pageSize = pageSize;
			this.getDownloadList();
		},
		// 新增发布
		createNews() {
			let valid = this.$refs.add.validateForm();
			console.log("valid", valid);
			if (valid) {
				console.log("form", this.form);
				this.$api.viparea
					.save(this.form)
					.then(r => {
						if (r.code == 200) {
							// this.$message.error("您发布的内容已提交至后台，待管理员审核通过后即可正式发布！");
							this.addVisible = false;
							this.reviewReminderVisible = true;
							this.form = {
								shareType: null,
								title: null,
								keyword: null,
								publishContent: null,
								fileId: null,
								shareSource: 611102,
								consumerId: this.$session.getUsers().consumerId,
							};
							this.$refs.add.handleRemove(this.$refs.add.fileList[0]);
							this.$refs.add.filePic = "";
							this.handleOption(1);
						}
					})
					.catch(e => {
						this.$message.error(e.msg);
						this.addVisible = false;
					});
			} else {
				return false;
			}
		},
		// 发布按钮
		handleAdd() {
			if (this.memberId == 7) {
				this.$confirm("办理会员，即可发布内容！", "提示", {
					confirmButtonText: "去购买",
					cancelButtonText: "我知道了",
					type: "warning",
				})
					.then(() => {
						this.toMember();
					})
					.catch(() => {
						console.log("用户点击取消");
					});
			} else {
				this.form={
					shareType: null,
					title: null,
					keyword: null,
					publishContent: null,
					fileId: null,
					shareSource: 611102,
					consumerId: null,
				}
				this.addVisible = true;
			}
		},
		toMember(isUpgradation) {
			let url = this.$router.resolve({
				path: `/member`,
				query: {
					isUpgradation: isUpgradation,
				},
			});
			window.open(url.href, "_blank");
		},
		cancel() {
			this.addVisible = false;
		},
		// 去详情
		toDetail(shareId) {
			this.shareId = shareId;
			this.getDetail();
			console.log("shareId", shareId);
		},
		// 获取详情
		getDetail() {
			this.$api.viparea
				.getShareInfo({
					shareId: this.shareId,
				})
				.then(res => {
					this.detailData = res.data;
					if (this.detailData.fileExtension) {
						this.detailData.filePic = require("@/assets/img/file/" + this.detailData.fileExtension.toLowerCase() + ".png");
					}
					this.detailVisible = true;
				});
			// 累加阅读数
			this.$api.viparea
				.realReadingPlusOne({
					shareId: this.shareId,
				})
				.then(res => {
					console.log(res);
				});
		},
		// 去下载
		toDownload(row) {
			this.fileDownload(row.ossFileUrl, row.fileName);
		},
		// 调用downLoad方法下载文件，以下是具体方法：
		//************************修改文件下载的名称*********start************************
		/**
		 * 获取 blob
		 * @param  {String} url 目标文件地址
		 * @return {Promise}
		 */
		getBlob(url) {
			return new Promise(resolve => {
				const xhr = new XMLHttpRequest();
				// 避免 200 from disk cache
				url = url + `?r=${Math.random()}`;
				xhr.open("GET", url, true);
				xhr.responseType = "blob";
				xhr.onload = () => {
					if (xhr.status === 200) {
						resolve(xhr.response);
					}
				};
				xhr.send();
			});
		},

		/**
		 * 保存
		 * @param  {Blob} blob
		 * @param  {String} filename 想要保存的文件名称
		 */
		saveAs(blob, filename) {
			if (window.navigator.msSaveOrOpenBlob) {
				navigator.msSaveBlob(blob, filename);
			} else {
				const anchor = document.createElement("a");
				const body = document.querySelector("body");
				anchor.href = window.URL.createObjectURL(blob);
				anchor.download = filename;
				console.log(filename, "filename");
				anchor.style.display = "none";
				body.appendChild(anchor);
				anchor.click();
				body.removeChild(anchor);
				window.URL.revokeObjectURL(anchor.href);
			}
			//存储下载记录
			let dataParams = {
				// localFileUrl: savedFilePath,
				relationId: this.item.shareId,
				consumerId: this.$session.getUsers().consumerId,
				relationType: "60611011",
			};
			console.log("联网打开成功,下载记录 ", dataParams);
			//打开文件同时存储下载记录
			this.$api.viparea.shareDownloadFile(dataParams);
		},

		/**
		 * 下载
		 * @param  {String} url 目标文件地址
		 * @param  {String} newFilename 想要保存的文件名称
		 */
		async fileDownload(url, newFilename) {
			const blob = await this.getBlob(url);
			console.log(blob, "blob");
			this.saveAs(blob, newFilename);
		},
		// 推荐模块搜索
		recommendationSearch() {
			this.conPO.pageNum = 1;
			this.getList();
		},
		mySearch() {
			if (this.myActiveName == "我的发布") {
				this.getList(this.myActiveName);
			} else if (this.myActiveName == "我的收藏") {
				this.getCollectionList();
			} else if (this.myActiveName == "我的关注") {
				this.getfollowPage();
			} else if (this.myActiveName == "我的下载") {
				this.getDownloadList();
			}
		},
	},
};
</script>
<style lang="less">
@import "./index.less";
</style>
