<template>
	<div class="list-item" @click="toDetail(item.shareId)" :class="item.isTop == 1 ? 'isTop' : 'noTop'">
		<div class="row-1 flex items-center">
			<div class="header">
				<img class="header-img" :src="item.imgUrl || VUE_APP_FILEURL + 'public-mticloud-app-mds/2024/05/07/b7d03199-d925-45da-98d0-ffadcebc2f69.png'" />
				<div class="isfollow" v-if="item.isFollow == 1">
					<i class="el-icon-success" style="color:#3E7EF2 ;"></i>
				</div>
			</div>
			<div class="nickname ml-24">{{ item.userName }}</div>
			<template v-if="item.shareSource && item.shareSource == 611101">
				<!-- <img class="vip ml-8" v-if="type != '我的发布'" :src="VUE_APP_FILEURL + 'public-mticloud-app-mds/2024/05/07/82c25966-7c19-422f-a933-c81ce616154f.png'" /> -->
				<template v-if="type != '我的发布'">
					<img class="vip ml-8" v-if="redType == 1" :src="VUE_APP_FILEURL + 'public-mticloud-app-mds/2024/05/07/330ffe30-9ed2-497e-8353-38bf3fcf2f0a.png'" />
					<img class="vip ml-8" v-if="redType == 2" :src="VUE_APP_FILEURL + 'public-mticloud-app-mds/2024/05/07/82c25966-7c19-422f-a933-c81ce616154f.png'" />
				</template>
			</template>
			<template v-if="type == '我的发布'">
				<!-- 通过 -->
				<img class="state" v-if="item.publishState == 101403" mode="widthFix" :src="VUE_APP_FILEURL + 'public-mticloud-app-mds/2024/05/07/3b1a232a-de81-4eee-b0b2-92ee3a52320f.png'" />
				<!-- 不通过 -->
				<img class="state" v-if="item.publishState == 101404" mode="widthFix" :src="VUE_APP_FILEURL + 'public-mticloud-app-mds/2024/05/07/c4e6e86a-3e49-4869-bdb9-d60417c97ef0.png'" />
				<!-- 审核中 -->
				<img class="state" v-if="item.publishState == 101402" mode="widthFix" :src="VUE_APP_FILEURL + 'public-mticloud-app-mds/2024/05/07/55c947ce-0894-429e-a73f-a6db79ea5b2e.png'" />
			</template>
			<div class="time flex-1 text-right">{{ $utils.timeAgo(item.publishState == 101403 && type != "我的发布" && item.publishDate ? item.publishDate : item.createDate) }}</div>
		</div>
		<div class="row-2 mt-12 flex items-center">
			<div class="type mr-12" v-for="(ite, i) in typeList" :key="i" v-show="(type == '全部' || type == '我的发布' || type == '我的收藏') && item.shareType == ite.dictValue">{{ ite.dictLabel }}</div>
			<div class="title  line-clamp-1" v-html="item.title"></div>
		</div>
		<div class="row-3 mt-14 line-clamp-3 h-56" v-html="item.publishContent"></div>
		<div class="row-4 mt-24 flex h-24">
			<div class="keyword line-clamp-1" v-show="item.keyword" v-html="item.keyword"></div>
		</div>
		<div class="row-5 mt-20 flex items-center">
			<div class="info">阅读数: {{ item.realReading }}</div>
			<div class="info ml-32">下载量: {{ item.realDownload }}</div>
			<div class="flex-1"></div>
			<collect class="ml-32" :ab="false" v-model="item.isCollection" :itemId="item.shareId" type="会员专区" />
		</div>
	</div>
</template>

<script>
import collect from "@/components/collect";
export default {
	name: "listItem",
	components: {collect},
	data() {
		return {
			VUE_APP_FILEURL: process.env.VUE_APP_FILEURL,
			redType: 0, //会员类型
		};
	},
	props: {
		item: {
			type: Object,
			default: () => {
				return {};
			},
		},
		typeList: {
			type: Array,
			default: () => {
				return [];
			},
		},
		showType: {
			type: Boolean,
			default: false,
		},
		type: {
			type: String,
			default: "",
		},
		showState: {
			type: Boolean,
			default: false,
		},
	},
	mounted() {
		this.$nextTick(() => {
			let huiyuanRed = false;
			let vipRed = false;
			let tbRed = false;
			if (this.item.mdsMemberSharePermissionVOS) {
				this.item.mdsMemberSharePermissionVOS.map(item => {
					if (item.memberId == 3 && item.isCheck == 10011001) {
						huiyuanRed = true;
					}
					if (item.memberId == 1 && item.isCheck == 10011001) {
						vipRed = true;
					}
					if (item.memberId == 5 && item.isCheck == 10011001) {
						tbRed = true;
					}
				});
				this.redType = 0;
				if (huiyuanRed) {
					this.redType = 1;
				}
				if (!huiyuanRed && vipRed) {
					this.redType = 2;
				}
				if (!huiyuanRed && !vipRed && tbRed) {
					console.log("huiyuanRed", huiyuanRed, "vipRed", vipRed, "tbRed", tbRed);
				}
			}
		});
	},
	methods: {
		handleShare() {
			this.$emit("share");
		},
		async toDetail(shareId) {
			let item = this.item;
			if (item.shareSource == 611101) {
				let ruleTable = item.mdsMemberSharePermissionVOS; //权限列表
				let memberId = this.$session.getUsers().memberId; //用户身份
				let canRed = false; //阅读权限
				let buyvip = "";
				let isUpgradation = "";
				ruleTable.map(item => {
					if (item.memberId == memberId && item.isCheck == 10011001) {
						canRed = true;
					}
				});
				if (canRed || memberId == 6) {
					this.toRead(item.shareId);
				} else {
					let str = "";
					if (memberId == 1 || memberId == 3 || memberId == 5 || memberId == 7) {
						//注册用户
						let huiyuanRed = false;
						let vipRed = false;
						let tbRed = false;

						ruleTable.map(item => {
							if (item.memberId == 3 && item.isCheck == 10011001) {
								huiyuanRed = true;
							}
							if (item.memberId == 1 && item.isCheck == 10011001) {
								vipRed = true;
							}
							if (item.memberId == 5 && item.isCheck == 10011001) {
								tbRed = true;
							}
						});

						console.log("huiyuanRed", huiyuanRed);
						console.log("vipRed", vipRed);
						if (huiyuanRed) {
							str = "尊敬的用户，该内容仅限会员查看";
						}
						if (!huiyuanRed && vipRed) {
							str = "尊敬的用户，该内容仅限VIP会员查看";
							buyvip = "update";
							isUpgradation = "upgradation";
						}
						if (!huiyuanRed && !vipRed && tbRed) {
							str = "尊敬的用户，该内容仅限特别会员查看";
							this.$message.warning(str);
							return;
						}
						if (!huiyuanRed && !vipRed && !tbRed) {
							str = "尊敬的用户，该内容仅限管理员查看";
							this.$message.warning(str);
							return;
						}
					} else if (memberId == 3) {
						//普通会员
						str = "尊敬的用户，该内容仅限VIP会员查看";
						isUpgradation = "upgradation";
					}
					console.log(123);
					await this.$confirm(str, "提示", {
						confirmButtonText: "去购买",
						cancelButtonText: "我知道了",
						type: "warning",
					})
						.then(() => {
							this.toMember(isUpgradation);
						})
						.catch(() => {
							console.log("用户点击取消");
						});
				}
				return;
			}
			this.toRead(item.shareId);
		},
		toMember(isUpgradation) {
			let url = this.$router.resolve({
				path: `/member`,
				query: {
					isUpgradation: isUpgradation,
				},
			});
			window.open(url.href, "_blank");
		},
		toRead(shareId) {
			this.$emit("toDetail", shareId);
		},
	},
};
</script>

<style lang="less">
/* 不加密 */
.mgc-text {
	display: none;
}
.list-item {
	padding: 13px 15px;
	// border-bottom: 1px solid #e9eaef;
	box-sizing: border-box;
	/* 不加密 */

	.zhiding {
		margin-top: 20px;
		padding: 10px 50px;
		display: none;
		justify-content: center;
		align-items: center;
		font-size: 13px;
		color: rgba(102, 102, 102, 1);
	}
	.row-1 {
		.header {
			position: relative;

			.header-img {
				width: 45px;
				height: 45px;
				border-radius: 50%;
			}

			.isfollow {
				position: absolute;
				height: 12px;
				width: 12px;
				background-color: #fff;
				border: 1px solid #aaa;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				top: 35px;
				right: 5px;
			}
		}

		.nickname {
			font-size: 17px;
			color: rgba(51, 51, 51, 1);
		}

		.vip {
			width: 76px;
			height: 20px;
		}

		.time {
			font-size: 12px;
			color: rgba(153, 153, 153, 1);
		}

		.state {
			width: 50px;
			max-height: 75px;
			opacity: 0.8;
			margin-left: 10px;
		}
	}

	.row-2 {
		.type {
			white-space: nowrap;
			width: max-content;
			flex-shrink: 0;
			padding: 5px 12px;
			opacity: 0.9;
			border-radius: 4px;
			background: #3e7ef2;
			color: #fff;
			font-size: 12px;
		}

		.title {
			font-size: 17px;
			font-weight: bold;
			color: rgba(51, 51, 51, 1);
		}
	}

	.row-3 {
		font-size: 14px;
		color: rgba(102, 102, 102, 1);
	}

	.row-4 {
		.keyword {
			border-radius: 10px;
			background: #f0f4f9;
			padding: 5px 19px;
			font-size: 12px;
			color: #3e7ef2;
			line-height: 17px;
			margin-right: 10px;
		}
	}

	.row-5 {
		position: relative;
		.info {
			font-size: 12px;
			color: rgba(153, 153, 153, 1);
		}
		.share {
			position: absolute;
			right: 65px;
			width: 50px;
			height: 20px;
			bottom: 0px;
			border: none;
		}
	}
}
</style>
