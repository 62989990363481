<template>
	<div class="form-box">
		<el-form ref="form" :model="form" :rules="rules" label-width="80px">
			<div class="top-box px-20 pt-20">
				<el-form-item label="内容分类" prop="shareType">
					<el-select v-model="form.shareType" class="w-full" placeholder="请选择内容分类">
						<el-option v-for="item in typeList" :key="item.dataId" :label="item.dictLabel" :value="item.dictValue"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="标题" prop="title">
					<el-input v-model="form.title" placeholder="请输入标题"></el-input>
				</el-form-item>
				<el-form-item label="关键词" prop="keyword">
					<el-input v-model="form.keyword" placeholder="关键词（发布内容关键词，例如：招聘需求、采购需求等）"></el-input>
				</el-form-item>
				<el-form-item label="正文" prop="publishContent">
					<el-input :autosize="{minRows: 6, maxRows: 10}" type="textarea" v-model="form.publishContent" placeholder="请输入正文"></el-input>
				</el-form-item>
				<!-- </div>
			<div class="bottom-box mt-10"> -->
				<el-form-item label="附件上传" prop="fileId">
					<el-upload ref="upload" list-type="picture-card" class="viparea-upload" accept=".doc, .docx,.pdf,.zip,.rar,.7z,.xls,.xlsx,.jpg,.png" :file-list="fileList" :action="uploadFileUrl" :limit="1" :on-exceed="handleOutRange" :on-preview="handlePreviewReport" :on-change="handleChangeReport" :on-success="handleReportSuccess" :before-upload="beforeReportUpload">
						<i slot="default" class="el-icon-plus"></i>
						<div slot="file" slot-scope="{file}" class="text-center">
							<img class="el-upload-list__item-thumbnail" style="width:  80%;" :src="filePic" alt="" />
							<span class="fille-name">{{ file.fileName }}</span>
							<span class="el-upload-list__item-actions">
								<!-- <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
									<i class="el-icon-zoom-in"></i>
								</span>
								<span v-if="!disabled" class="el-upload-list__item-delete" @click="handleDownload(file)">
									<i class="el-icon-download"></i>
								</span> -->
								<span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
									<i class="el-icon-delete"></i>
								</span>
							</span>
							<span class="fille-name line-clamp-1">{{ file.name }}</span>
						</div>
						<span slot="tip" class="el-upload__tip">
							请仅上传一个文件，支持格式包括
							<span class="red">doc/docx/pdf/xls/xlsx</span>
							文档、
							<span class="red">jpg/png</span>
							图片以及
							<span class="red">rar/zip</span>
							压缩包，且文件大小不超过
							<span class="red">200MB</span>
							。
						</span>
					</el-upload>
					<el-dialog :visible.sync="dialogVisible">
						<img width="100%" :src="dialogImageUrl" alt="" />
					</el-dialog>
				</el-form-item>
				<div class="agree-code-box ml-80">
					<el-checkbox v-model="agreeCodeChecked" @change="changeChecked"></el-checkbox>
					<span class="content">我已阅读并同意</span>
					<span class="agree" @click="openAgree">《发布声明》</span>
				</div>
			</div>
		</el-form>
		<agreement-modal ref="agreementModal" :title="'发布声明'" :text="false"></agreement-modal>
	</div>
</template>
<script>
import AgreementModal from "@/components/agreementModal/viparea";
export default {
	name: "",
	components: {
		AgreementModal,
	},
	mixins: [],
	props: {
		typeList: {
			type: Array,
			default: () => {
				return [];
			},
		},
		form: {
			type: Object,
			default: () => {
				return {};
			},
		},
	},
	data() {
		return {
			rules: {
				shareType: {
					type: "string",
					required: true,
					message: "请选择内容分类",
					trigger: ["blur", "change"],
				},
				title: {
					type: "string",
					required: true,
					message: "请输入标题",
					trigger: ["blur"],
				},
				publishContent: {
					type: "string",
					required: true,
					message: "请输入正文",
					trigger: ["blur"],
				},
			},
			dialogImageUrl: "",
			dialogVisible: false,
			disabled: false,
			// 文件列表
			fileList: [],
			uploadFileUrl: process.env.VUE_APP_BASE_URL + "/mds/api/file/upload",
			filePic: "",
			agreeCodeChecked: false,
		};
	},
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		// 点击协议
		openAgree() {
			this.$refs.agreementModal.visible = true;
		},
		changeChecked(e) {
			console.log("e", e);
			console.log("agreeCodeChecked", this.agreeCodeChecked);
		},
		// 附件删除
		handleRemove(file) {
			console.log("fileRemove", file);
			console.log("fileListRemove", this.fileList);
			this.$refs.upload.handleRemove(file);
			this.filePic = "";
			setTimeout(() => {
				this.$refs.upload.$children[1].$el.style.display = "";
			}, 1000);
		},
		// 放大镜按钮
		handlePictureCardPreview(file) {
			this.dialogImageUrl = file.url;
			this.dialogVisible = true;
			// console.log(file);
		},
		//下载按钮
		handleDownload() {},
		// 附件变动
		handleChangeReport(file, fileList) {
			console.log("fileChange", file);
			console.log("fileListChange", fileList);
			console.log("this.fileListChange", this.fileList);
			this.fileList = fileList;
			if (this.fileList.length > 0) {
				// 隐藏上传按钮
				this.$refs.upload.$children[1].$el.style.display = "none";
			} else {
				// 显示上传按钮
				this.$refs.upload.$children[1].$el.style.display = "";
			}
		},
		//附件上传成功
		handleReportSuccess(res, file) {
			console.log("res", res);
			console.log("fileList", this.fileList);
			if (res.code == 200) {
				this.form.fileId = res.data.fileId;
				if (res.data.fileExtension == "png" || res.data.fileExtension == "jpg") {
					this.filePic = res.data.url;
				} else {
					this.filePic = require("@/assets/img/file/" + res.data.fileExtension.toLowerCase() + ".png");
				}
			} else {
				this.$refs.upload.handleRemove(file);
				this.$message.error(res.msg);
			}
		},
		// 上传文件之前的钩子
		beforeReportUpload(file) {
			const isLt200M = file.size / 1024 / 1024 < 200;
			if (!isLt200M) {
				this.$message.error("上传文件大小不能超过200M!");
			}
			return isLt200M;
		},
		// 点击文件列表中已上传的文件时的钩子
		handlePreviewReport() {},
		// 超出限制
		handleOutRange() {
			this.$message.error("超出限制数量，如有多个文件，请上传压缩包！");
		},
		validateForm() {
			let flag = null;
			this.$refs.form.validate(valid => {
				if (valid) {
					flag = true;
					if (!this.agreeCodeChecked) {
						this.$message.error("请勾选用户协议");
						flag = false;
						return false;
					}
				} else {
					flag = false;
					return false;
				}
			});
			return flag;
		},
	},
};
</script>
<style lang="less" scoped>
.form-box {
	// .top-box {
	// 	// border: 1px solid #f2f2f2;
	// }
	.top-box {
		::v-deep .viparea-upload {
			.el-upload--picture-card {
				vertical-align: bottom;
			}
			.el-upload-list--picture-card {
				position: relative;
				top: 22px;
				vertical-align: bottom;
			}
		}
		.el-upload__tip {
			margin-left: 10px;
			.red {
				color: rgb(216, 0, 27);
			}
		}
		.agree-code-box {
			margin-top: 15px;
			display: flex;
			align-items: center;
			justify-content: start;
			.el-checkbox__inner {
				width: 14px;
				height: 14px;
				border-color: #98a3b7;
			}
			.el-checkbox__input.is-checked .el-checkbox__inner,
			.el-checkbox__input.is-indeterminate .el-checkbox__inner {
				border-color: transparent;
			}
			.content {
				margin-left: 0.37vw;
				font-size: 0.73vw;
				color: #333333;
			}
			.agree {
				font-size: 0.73vw;
				color: #1181fa;
				cursor: pointer;
			}
		}
	}
}
</style>
