<template>
	<div class="list-item-follow">
		<div class="row-1 flex items-center">
			<div class="header">
				<img class="header-img" :src="item.imgUrl||(VUE_APP_FILEURL+'public-mticloud-app-mds/2024/05/07/b7d03199-d925-45da-98d0-ffadcebc2f69.png')"/>
				<div class="isfollow" v-if="item.isFollow==1">
					<u-icon name="checkmark" size="24px" color="#3E7EF2"></u-icon>
				</div>
			</div>
			<div class="user ml-20 flex-1">
				<div class="nickname">{{ item.followConsumerName||'慧决策' }}</div>
				<div class="userinfo flex mt-12">
					<div>{{ item.publishCount }} 发布</div>
					<div class="ml-10">·</div>
					<div class="ml-10">{{ item.followCount }} 关注者</div>
				</div>
			</div>
			<follow v-model="isFollow" :followConsumerId="item.consumerId"></follow>
		</div>
	</div>
</template>

<script>
	import collect from '@/components/collect/index'
	import follow from '@/components/follow'
	export default {
		name: 'listItemFollow',
		components: {
			collect,
			follow
		},
		data() {
			return {
				isFollow:1,
				VUE_APP_FILEURL:process.env.VUE_APP_FILEURL
			};
		},
		props: {
			item: {
				type: Object,
				default: () => {
					return {}
				}
			}
		},
		watch: {
			isFollow(newValue, oldValue) {
				if(newValue==0){
					this.$emit('cannel')
				}
			}
		},
		onLoad() {
			
		},
		methods: {
		}
	};
</script>

<style lang="less" >
	.list-item-follow {
		padding: 26px 32px;
       
		.row-1 {
			.header {
				position: relative;
				.header-img {
					width: 45px;
					height: 45px;
					border-radius: 50%;
				}

				.isfollow {
					position: absolute;
					height: 25px;
					width: 25px;
					background-color: #fff;
					border: 1px solid #aaa;
					border-radius: 50%;
					display: flex;
					justify-content: center;
					align-items: center;
					top: 70px;
					right: 5px;
				}
			}
			.user{
				.nickname {
					font-size: 17px;
					color: rgba(51, 51, 51, 1);
				}
				.userinfo {
					font-size: 12px;
					color: rgba(153, 153, 153, 1);
				} 
			}
			.follow{
				width: max-content !important;  
				font-size: 14px;
				color: #999;
				display: flex; 
				align-items: center;
				background-color: aliceblue;
				padding: 15px 25px;
				border-radius: 32px;
				white-space: nowrap;
			}
		}
	}
</style>