<!-- 收藏组件 -->
<template>
	<div v-if="!!USER" :class="[type == 1 ? 'follow' : 'follow2', value == 0 ? 'active' : '']" @click.stop="handleFollow">
		{{ value == 1 ? "已关注" : "关注" }}
	</div>
</template>
<script>
import {mapGetters} from "vuex";
export default {
	name: "follow",
	props: {
		ab: {
			type: Boolean,
			default: true,
		},
		value: {
			type: Number,
			default: 0,
		},
		followConsumerId: {
			type: [String, Number],
		},
		type: {
			type: Number,
			default: 1,
		},
	},
	data() {
		return {
			USER: {},
		};
	},
	mounted() {
		this.USER = this.$session.getUsers();
	},
	computed: {},
	methods: {
		handleFollow() {
			let params = {
				consumerId: this.USER.consumerId,
				followConsumerId: this.followConsumerId,
			};
			let _this = this;
			if (this.value == 1) {
				//取消
				this.$confirm("您确认要取消这个关注吗？", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				})
					.then(() => {
						const loading = _this.$loading({
							lock: true,
							text: "加载中...",
							spinner: "el-icon-loading",
							background: "rgba(255, 255, 255, 0.3)",
							fullscreen: false,
						});
						this.$api.viparea
							.followOrCancel(params)
							.then(res => {
								if (res.code == 200) {
									this.$emit("input", this.value == 1 ? 0 : 1);
									this.$message({
										message: "已取消",
										type: "success",
									});
								} else {
									this.$message.error(res.msg);
								}
							})
							.finally(() => {
								loading.close();
							});
					})
					.catch(error => {
						// this.$message.error(error);
					})
					.finally(() => {
						loading.close();
					});
			} else {
				//关注
				const loading = _this.$loading({
					lock: true,
					text: "加载中...",
					spinner: "el-icon-loading",
					background: "rgba(255, 255, 255, 0.3)",
					fullscreen: false,
				});
				this.$api.viparea
					.followOrCancel(params)
					.then(res => {
						if (res.code == 200) {
							this.$emit("input", this.value == 1 ? 0 : 1);
							this.$message.success("关注成功");
						} else {
							this.$message.error(res.msg);
						}
					})
					.finally(() => {
						loading.close();
					});
			}
		},
	},
};
</script>
<style scoped>
.follow {
	width: max-content !important;
	font-size: 28px;
	color: #999;
	display: flex;
	align-items: center;
	background-color: aliceblue;
	padding: 15px 25px;
	border-radius: 32px;
	white-space: nowrap;
}

.follow2 {
	width: max-content !important;
	font-size: 28px;
	color: #333;
	display: flex;
	align-items: center;
	background-color: #ffffff;
	padding: 15px 25px;
	border-radius: 32px;
	white-space: nowrap;
}

.active {
	background-color: aliceblue;
	color: #1181fa !important;
}
</style>
